import styled from 'styled-components';

/* Footer Container */
const StFooter = styled.footer`
  display: flex;
  justify-content: center;
  background-color: #ffffff; /* White background */
  padding: 60px 20px 40px;
`;

/* Main Column Wrapper */
const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  width: 100%;
`;

/* Logo Image */
const LogoImage = styled.img`
  width: 80px;
  height: auto;
  margin-bottom: 10px;
`;

/* Title Text */
const LogoText = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #000000; /* Black */
  margin-bottom: 16px;
`;

/* Info Text */
const InfoText = styled.p`
  font-size: 14px;
  color: #555555;
  margin: 5px 0;
  text-align: center;
`;

/* Transparent Button */
const ReservationButton = styled.button`
  margin-top: 30px;
  padding: 10px 22px;
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;
  color: #000000;
  border: 1.5px solid #000000;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

/* Divider Line */
const Divider = styled.div`
  width: 100%;
  max-width: 500px;
  border-top: 1px solid #dcdcdc;
  margin: 40px 0 20px;
  opacity: 0.7;
`;

/* Copyright Text */
const CopyrightText = styled.p`
  font-size: 12px;
  color: #888888;
  text-align: center;
`;

export { StFooter, FooterContent, LogoImage, LogoText, InfoText, ReservationButton, Divider, CopyrightText };
