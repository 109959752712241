import styled from 'styled-components';

const StWrapFacilities = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: relative;
  padding: 40px;
`;

/* Modern Sidebar */
const FacilitiesSidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
  padding: 10px 0;
  align-items: center;
  text-align: center;
`;

const SidebarHeader = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #2e7d32;
  border-bottom: 2px solid #2e7d32;
  width: 100%;
  padding-bottom: 5px;
`;

const FacilityButton = styled.button<{ isActive?: boolean }>`
  width: 100%;
  padding: 10px 0;
  margin: 5px 0;
  background: none;
  color: ${({ isActive }) => (isActive ? '#2e7d32' : '#555')};
  border: none;
  font-size: 16px;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    color: #2e7d32;
  }

  &:after {
    content: '';
    display: block;
    width: ${({ isActive }) => (isActive ? '100%' : '0')};
    height: 3px;
    background-color: #2e7d32;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.3s ease;
  }

  &:hover:after {
    width: 100%;
  }
`;

/* Carousel */
const CarouselWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

const CarouselOuterContainer = styled.div`
  display: flex;
  width: 650px;
  overflow: hidden;
`;

const CarouselContainer = styled.div`
  display: flex;
  width: 650px;
  transition: transform 0.3s ease-in-out;
`;

/* Facility Cards */
const FacilityCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 650px;
  height: 500px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  flex-shrink: 0;
`;

const FacilityImageWrapper = styled.div`
  width: 100%;
  height: 55%;
  overflow: hidden;
`;

const FacilityImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FacilityTextWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FacilityTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #2c6e49;
`;

const FacilityText = styled.p`
  font-size: 16px;
  color: #444;
`;

/* Arrows */
const ArrowWrapper = styled.div<{ left?: boolean; right?: boolean }>`
  display: flex;
  width: 50px;
  justify-content: ${({ left }) => (left ? 'flex-start' : 'flex-end')};
`;

const ArrowButton = styled.button`
  background: transparent;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: #2c6e49;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

export {
  StWrapFacilities,
  FacilitiesSidebar,
  SidebarHeader,
  FacilityButton,
  FacilityCard,
  FacilityImageWrapper,
  FacilityImage,
  FacilityTextWrapper,
  FacilityTitle,
  FacilityText,
  CarouselWrapper,
  CarouselOuterContainer,
  CarouselContainer,
  ArrowWrapper,
  ArrowButton
};
