import styled from 'styled-components';

/* Main Wrapper */
const StWrapHome = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-image: url('https://snine-assets.s3.ap-northeast-2.amazonaws.com/images/outside/1.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Ensures the background stays fixed */
`;

const Spacer = styled.div`
  width: 100%;
  height: 500px;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  position: relative;
  z-index: 2;
`;

const SpacerOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for contrast */
`;

const SpacerContent = styled.div`
  position: relative;
  max-width: 900px;
  padding: 20px;
  z-index: 3;
`;

const SpacerTitle = styled.h1`
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 1.5px;
  color: #f5f5f5;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  margin-bottom: 10px;
`;

const SpacerHeading = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #f5f5f5;
  margin-bottom: 15px;
`;

const SpacerDescription = styled.p`
  font-size: 16px;
  color: #e0e0e0;
  line-height: 1.6;
  max-width: 700px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #ffffff; /* Solid background */
  z-index: 2; /* Above the background image */
`;

const RoomHeaderWrapper = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  margin: 60px 0 30px 0;
`;

const RoomDivider = styled.div`
  width: 2px;
  height: 40px;
  background-color: black;
  margin-right: 12px;
  border-radius: 2px;
`;

const RoomHeader = styled.h1`
  font-size: 36px;
  font-weight: 500;
  color: black;
  font-family: 'Georgia', serif;
  margin: 0;
  letter-spacing: -0.4px;
`;

const RoomCard = styled.div<{ flipped?: boolean }>`
  width: 80%;
  margin: 25px;
  display: flex;
  flex-direction: ${({ flipped }) => (flipped ? 'row-reverse' : 'row')};
  align-items: center;
  height: 280px;
  background-color: #ffffff;
  overflow: hidden;
`;

const RoomSpacer = styled.div`
  width: 30%;
  height: 100%;
`;

const RoomImageDiv = styled.div`
  width: 50%;
  height: 100%;
  overflow: hidden;
`;

const RoomImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${RoomCard}:hover & {
    transform: scale(1.05);
  }
`;

const RoomMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  padding: 20px 30px;
  background-color: #f9f9f9;
  height: 100%;
`;

const RoomTextHeader = styled.h2`
  font-size: 22px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 5px;
`;

const RoomTextBody = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #34495e;
  margin-bottom: 15px;
`;

const RoomDetails = styled.p`
  font-size: 16px;
  color: #7f8c8d;
  margin-bottom: 20px;
`;

const RoomButton = styled.button`
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #2e7d32;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background-color: #1b5e20;
  }
`;

const Divider = styled.hr`
  width: 90%;
  margin: 40px auto;
  border: none;
  border-top: 1px solid #e0e0e0;
`;

/* Facilities Section */
const FacilitiesSection = styled.div`
  width: 95%;
  max-width: 1200px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const FacilitiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 100px;
  width: 95%;
  max-width: 1000px;
  margin: 40px auto;
`;

const FacilityTile = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  aspect-ratio: 1 / 1; /* Makes each tile square */
`;

const FacilityImage = styled.img`
  width: 100%;
  height: 80%; /* Takes up 75% of the tile */
  object-fit: cover;
  transition: transform 0.3s ease;

  ${FacilityTile}:hover & {
    transform: scale(1.05);
  }
`;

const FacilityTitle = styled.div`
  padding: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #2c3e50;
  background-color: #ffffff;
`;

/* Map Section */
const LocationSection = styled.div`
  width: 100%;
  position: relative;
  height: 400px; /* Adjust height for better spacing */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const LocationOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1));
  z-index: 1;
`;

const LocationContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  max-width: 1200px;
  z-index: 2;
`;

const LocationInnerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const LocationTextWrapper = styled.div`
  flex: 1;
  color: white;
  text-align: left;
`;

const LocationTitle = styled.h2`
  font-size: 32px;
  margin-bottom: 10px;
  font-weight: bold;
  color: white;
`;

const LocationDescription = styled.p`
  font-size: 18px;
  color: #f0f0f0;
`;

const HomeMap = styled.div`
  flex: 1;
  max-width: 500px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
`;

const ExperienceMoreSection = styled.div`
  background: white;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ExperienceTitle = styled.h2`
  font-size: 48px;
  font-weight: 500;
  color: #1a2d4e;
  text-align: center;
  font-family: 'Georgia', serif;
  margin-bottom: 60px;
  line-height: 1.4;
`;

const ExperienceItems = styled.div`
  display: flex;
  justify-content: center;
  gap: 60px;
  flex-wrap: wrap;
  width: 90%;
  max-width: 1200px;
`;

const ExperienceItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  text-align: center;
`;

const ExperienceImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 16px;
`;

const ExperienceItemTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #2e7d32;
`;

const ExperienceItemDesc = styled.p`
  font-size: 14px;
  color: #555;
  line-height: 1.5;
`;

export {
  StWrapHome,
  Spacer,
  SpacerHeading,
  SpacerOverlay,
  SpacerContent,
  SpacerTitle,
  SpacerDescription,
  ContentWrapper,
  RoomHeader,
  RoomHeaderWrapper,
  RoomDivider,
  RoomCard,
  RoomSpacer,
  RoomImageDiv,
  RoomImage,
  RoomMain,
  RoomTextHeader,
  RoomTextBody,
  RoomDetails,
  RoomButton,
  Divider,
  FacilitiesSection,
  FacilitiesGrid,
  FacilityTile,
  FacilityImage,
  FacilityTitle,
  LocationSection,
  LocationOverlay,
  LocationContent,
  LocationInnerWrapper,
  LocationTextWrapper,
  LocationTitle,
  LocationDescription,
  HomeMap,
  ExperienceMoreSection,
  ExperienceTitle,
  ExperienceItems,
  ExperienceItem,
  ExperienceImage,
  ExperienceItemTitle,
  ExperienceItemDesc
};
