import React from 'react';
import {
  StWrapHome,
  Spacer,
  SpacerHeading,
  SpacerOverlay,
  SpacerContent,
  SpacerTitle,
  SpacerDescription,
  ContentWrapper,
  RoomHeaderWrapper,
  RoomDivider,
  RoomHeader,
  RoomCard,
  RoomImageDiv,
  RoomImage,
  RoomMain,
  RoomTextHeader,
  RoomTextBody,
  RoomDetails,
  RoomButton,
  Divider,
  FacilitiesSection,
  FacilitiesGrid,
  FacilityTile,
  FacilityImage,
  FacilityTitle,
  LocationSection,
  LocationOverlay,
  LocationContent,
  LocationTitle,
  LocationDescription,
  LocationInnerWrapper,
  LocationTextWrapper,
  HomeMap,
  RoomSpacer,
  ExperienceMoreSection,
  ExperienceTitle,
  ExperienceItems,
  ExperienceItem,
  ExperienceImage,
  ExperienceItemTitle,
  ExperienceItemDesc
} from './styles';
import KakaoMap from './KakaoMap';
import { useNavigate } from 'react-router-dom';
import { FacilitiesData } from '../../data/facilitiesInfo';
import { buildings } from '../../data/roomInfo';

function Home() {
  const navigate = useNavigate();

  return (
    <StWrapHome>
      <Spacer>
        <SpacerOverlay />
        <SpacerContent>
          <SpacerTitle>S9 Trail</SpacerTitle>
          <SpacerHeading>웅장한 자연과 다양한 시설을 모두 가진 다재다능의 S9 팬션!</SpacerHeading>
          <SpacerDescription>
            S9팬션은 축령산 아래 위치하여, 선선한 산책로와 시원한 계곡의 우창한 자연을 자랑함과 동시에 은행 나무
            연수원과 함께 운영하고 있기에 다른 팬션들과 차별화된 다양하고 색다른 시설들을 자랑합니다.
          </SpacerDescription>
        </SpacerContent>
      </Spacer>
      <ContentWrapper>
        {/* Rooms Section */}
        <RoomHeaderWrapper>
          <RoomDivider />
          <RoomHeader>객실</RoomHeader>
        </RoomHeaderWrapper>

        {buildings.map((building, index) => {
          const firstRoomKey = Object.keys(building.rooms)[0]; // Get the first room in the building
          const firstRoom = building.rooms[firstRoomKey];

          return (
            <RoomCard key={index} flipped={index % 2 !== 0}>
              <RoomSpacer></RoomSpacer>
              <RoomImageDiv>
                <RoomImage src={firstRoom.imageUrl[0]} alt={`${building.buildingName} ${firstRoomKey}`} />
              </RoomImageDiv>
              <RoomMain>
                <RoomTextHeader>{building.buildingName}</RoomTextHeader>
                <RoomTextBody>{firstRoomKey}</RoomTextBody>
                <RoomDetails>
                  최대 {firstRoom.personnel} · {firstRoom.category}
                </RoomDetails>
                <RoomButton onClick={() => navigate('/reservation')}>예약하기</RoomButton>
              </RoomMain>
            </RoomCard>
          );
        })}

        <Divider />

        {/* Facilities Section - Tiled Layout */}
        <FacilitiesSection>
          <RoomHeaderWrapper>
            <RoomDivider />
            <RoomHeader>시설</RoomHeader>
          </RoomHeaderWrapper>
          <FacilitiesGrid>
            {FacilitiesData.map((facility, index) => (
              <FacilityTile key={index}>
                <FacilityImage src={facility.placeImg} alt={facility.title} />
                <FacilityTitle>{facility.title}</FacilityTitle>
              </FacilityTile>
            ))}
          </FacilitiesGrid>
        </FacilitiesSection>
      </ContentWrapper>

      <ExperienceMoreSection>
        <ExperienceTitle>
          EXPERIENCE
          <br />
          MORE
        </ExperienceTitle>

        <ExperienceItems>
          <ExperienceItem>
            <ExperienceImage src="/images/about.jpg" alt="About us" />
            <ExperienceItemTitle>About us</ExperienceItemTitle>
            <ExperienceItemDesc>
              청량한 자연 속<br />
              내려다 보는 환상적인 전망
            </ExperienceItemDesc>
          </ExperienceItem>

          <ExperienceItem>
            <ExperienceImage src="/images/facility.jpg" alt="Facility" />
            <ExperienceItemTitle>Facility</ExperienceItemTitle>
            <ExperienceItemDesc>
              사랑스러운 나의 반려견과
              <br />
              함께하는 편안한 여행
            </ExperienceItemDesc>
          </ExperienceItem>

          <ExperienceItem>
            <ExperienceImage src="/images/poolvilla.jpg" alt="Poolvilla" />
            <ExperienceItemTitle>Poolvilla</ExperienceItemTitle>
            <ExperienceItemDesc>
              편안함과 실용성을
              <br />
              객실 곳곳에 가득 담았습니다.
            </ExperienceItemDesc>
          </ExperienceItem>
        </ExperienceItems>
      </ExperienceMoreSection>

      {/* Map Section */}
      <LocationSection>
        <LocationOverlay />
        <LocationContent>
          <LocationInnerWrapper>
            <LocationTextWrapper>
              <LocationTitle>Location</LocationTitle>
              <LocationDescription>찾아오시는 길</LocationDescription>
            </LocationTextWrapper>
            <HomeMap>
              <KakaoMap />
            </HomeMap>
          </LocationInnerWrapper>
        </LocationContent>
      </LocationSection>
    </StWrapHome>
  );
}

export default Home;
