import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StHeader = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  height: 90px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

/* Centered logo */
const LogoDiv = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;

  &:visited,
  &:hover,
  &:active {
    color: black;
  }
`;

const LogoImage = styled.img`
  height: 45px;
  width: auto;
  margin-right: 10px;
`;

const LogoText = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
`;

/* Right-aligned nav */
const NavBar = styled.nav`
  position: absolute;
  right: 40px;
  display: flex;
  align-items: center;
  height: 100%;
`;

const NavBarRight = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
`;

const NavBarItem = styled.li`
  margin-left: 30px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  color: #2c3e50;
  transition: color 0.3s ease;

  &:hover {
    color: #388e3c;
  }
`;

const ReservationLink = styled(Link)`
  padding: 6px 14px;
  border: 1px solid black;
  border-radius: 20px;
  text-decoration: none;
  font-size: 14px;
  color: black;
  transition: color 0.3s ease;

  &:hover {
    color: #388e3c;
  }
`;

export {
  StHeader,
  LogoDiv,
  LogoLink,
  LogoImage,
  LogoText,
  NavBar,
  NavBarRight,
  NavBarItem,
  StyledLink,
  ReservationLink
};
