interface RoomDetails {
  roomName: string;
  personnel: string;
  category: string;
  hygiene: string[];
  cooking: string[];
  general: string[];
  significant: Array<string> | null;
  imageUrl: Array<string>;
}

interface Building {
  buildingName: string;
  rooms: { [key: string]: RoomDetails };
}

const baseUrl = 'https://snine-assets.s3.ap-northeast-2.amazonaws.com/images/rooms/';

const buildings: Building[] = [
  {
    buildingName: '노랑동',
    rooms: {
      '101호': {
        roomName: '노랑동 101호',
        personnel: '4/8',
        category: '복층, 월풀, 방2, 욕실2',
        hygiene: ['샴푸&린스', '수건', '치약', '비누'],
        cooking: [
          '냉장고',
          '전기밥솥',
          '무선전기주전자',
          '전자렌지',
          '취사시설',
          '식기구',
          '조리기구일체',
          '식탁',
          '개별바베큐'
        ],
        general: ['객실단독wifi', '화장대', '에어컨', 'TV', '헤어드라이', '옷걸이'],
        significant: null,
        imageUrl: [baseUrl + 'yellow_a/1.jpg', baseUrl + 'yellow_a/2.jpg', baseUrl + 'yellow_a/3.jpg']
      },
      '102호': {
        roomName: '노랑동 102호',
        personnel: '4/6',
        category: '복층, 월풀, 방2, 욕실2',
        hygiene: ['샴푸&린스', '수건', '치약', '비누'],
        cooking: [
          '냉장고',
          '전기밥솥',
          '무선전기주전자',
          '전자렌지',
          '취사시설',
          '식기구',
          '조리기구일체',
          '식탁',
          '개별바베큐'
        ],
        general: ['객실단독wifi', '화장대', '에어컨', 'TV', '헤어드라이', '옷걸이'],
        significant: null,
        imageUrl: [baseUrl + 'yellow_y102/1.jpg', baseUrl + 'yellow_y102/2.jpg', baseUrl + 'yellow_y102/3.jpg']
      }
    }
  },
  {
    buildingName: '파랑동',
    rooms: {
      '101호': {
        roomName: '파랑동 101호',
        personnel: '2/4',
        category: '월풀',
        hygiene: ['샴푸&린스', '수건', '치약', '비누'],
        cooking: [
          '냉장고',
          '전기밥솥',
          '무선전기주전자',
          '전자렌지',
          '취사시설',
          '식기구',
          '조리기구일체',
          '식탁',
          '개별바베큐'
        ],
        general: ['객실단독wifi', '화장대', '에어컨', 'TV', '헤어드라이', '옷걸이'],
        significant: ['202호: 월풀없음'],
        imageUrl: [baseUrl + 'blue_101/1.jpg', baseUrl + 'blue_101/2.jpg', baseUrl + 'blue_101/3.jpg']
      },
      '102호': {
        roomName: '파랑동 102호',
        personnel: '2/4',
        category: '월풀',
        hygiene: ['샴푸&린스', '수건', '치약', '비누'],
        cooking: [
          '냉장고',
          '전기밥솥',
          '무선전기주전자',
          '전자렌지',
          '취사시설',
          '식기구',
          '조리기구일체',
          '식탁',
          '개별바베큐'
        ],
        general: ['객실단독wifi', '화장대', '에어컨', 'TV', '헤어드라이', '옷걸이'],
        significant: ['202호: 월풀없음'],
        imageUrl: [baseUrl + 'blue_101/1.jpg', baseUrl + 'blue_101/2.jpg', baseUrl + 'blue_101/3.jpg']
      }
    }
  }
];

export { buildings };
