import React, { useState } from 'react';
import {
  StWrapFacilities,
  FacilitiesSidebar,
  FacilityButton,
  FacilityCard,
  FacilityImageWrapper,
  FacilityImage,
  FacilityTextWrapper,
  FacilityText,
  FacilityTitle,
  ArrowButton,
  CarouselWrapper,
  CarouselOuterContainer,
  CarouselContainer,
  ArrowWrapper,
  SidebarHeader
} from './styles';
import { FacilitiesData } from '../../data/facilitiesInfo';
import Banner from '../../components/banner';

function Facilities() {
  const [currentFacilityIndex, setCurrentFacilityIndex] = useState(0);

  const handleSwipe = (direction: 'left' | 'right') => {
    if (direction === 'left' && currentFacilityIndex > 0) {
      setCurrentFacilityIndex((prevIndex) => prevIndex - 1);
    } else if (direction === 'right' && currentFacilityIndex < FacilitiesData.length - 1) {
      setCurrentFacilityIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <>
      <Banner title="Facilities" text="Explore our amazing amenities" />
      <StWrapFacilities>
        {/* Sidebar Navigation */}
        <FacilitiesSidebar>
          <SidebarHeader>Facilities</SidebarHeader>
          <div className="facility-buttons">
            {FacilitiesData.map((facility, index) => (
              <FacilityButton
                key={facility.index}
                isActive={index === currentFacilityIndex}
                onClick={() => setCurrentFacilityIndex(index)}
              >
                {facility.title}
              </FacilityButton>
            ))}
          </div>
        </FacilitiesSidebar>

        {/* Facilities Carousel */}
        <CarouselWrapper>
          <ArrowWrapper left>
            <ArrowButton onClick={() => handleSwipe('left')} disabled={currentFacilityIndex === 0}>
              &lt;
            </ArrowButton>
          </ArrowWrapper>
          <CarouselOuterContainer>
            <CarouselContainer
              style={{
                transform: `translateX(-${currentFacilityIndex * 100}%)`,
                transition: 'transform 0.4s ease-in-out'
              }}
            >
              {FacilitiesData.map((facility) => (
                <FacilityCard key={facility.index}>
                  <FacilityImageWrapper>
                    <FacilityImage src={facility.placeImg} alt={facility.title} draggable="false" />
                  </FacilityImageWrapper>
                  <FacilityTextWrapper>
                    <FacilityTitle>{facility.title}</FacilityTitle>
                    <FacilityText>{facility.description}</FacilityText>
                  </FacilityTextWrapper>
                </FacilityCard>
              ))}
            </CarouselContainer>
          </CarouselOuterContainer>
          <ArrowWrapper right>
            <ArrowButton
              onClick={() => handleSwipe('right')}
              disabled={currentFacilityIndex === FacilitiesData.length - 1}
            >
              &gt;
            </ArrowButton>
          </ArrowWrapper>
        </CarouselWrapper>
      </StWrapFacilities>
    </>
  );
}

export default Facilities;
