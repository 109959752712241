import styled from 'styled-components';

const StRooms = styled.div<{ src: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Ensures the background stays fixed */
`;

const Spacer = styled.div`
  width: 100%;
  height: 500px;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  position: relative;
  z-index: 2;
`;

const SpacerOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for contrast */
`;

const SpacerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;
  z-index: 3;
`;

const RoomContentWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BuildingText = styled.h1`
  position: relative;
  color: white;
  font-size: 48px;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  margin: 0;
  z-index: 2;
`;

const RoomGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: 500px;
`;

const RoomGridTile = styled.div<{ image: string }>`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &:hover div {
    opacity: 1;
    transform: translateY(0);
  }
`;

const RoomGridOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
  opacity: 0.8;
`;

const RoomGridTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: white;
  margin: 0;
  padding: 20px;
`;

const ArrowLine = styled.div<{ right?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: white;
  flex-direction: ${({ right }) => (right ? 'row-reverse' : 'row')};
`;

const Line = styled.div`
  width: 60px;
  height: 2px;
  background-color: white;
`;

const Arrow = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

const RoomSelectorWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  padding: 20px 0;
`;

const RoomSelectorButton = styled.button<{ isActive?: boolean }>`
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 20px;
  border: 1px solid ${({ isActive }) => (isActive ? '#2e7d32' : '#ccc')};
  background-color: ${({ isActive }) => (isActive ? '#e6f4e8' : 'transparent')};
  color: ${({ isActive }) => (isActive ? '#2e7d32' : '#333')};
  cursor: pointer;
`;

const InfoGridWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 80px;
  padding: 60px 0;
`;

const InfoGridBlock = styled.div`
  text-align: center;
`;

const InfoTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 500;
  color: #333;
`;

const InfoIcon = styled.div`
  font-size: 32px;
  margin-bottom: 12px;
`;

const InfoText = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

const InfoTextSmall = styled.p`
  font-size: 14px;
  color: #666;
  margin-top: 4px;
`;

const FullRoomInfoSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 60px;
  align-items: flex-start;
  justify-content: space-between;
  font-family: 'Noto Sans KR', sans-serif;
  padding: 50px;
  max-width: 1200px;
`;

const RightAmenitySection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SmallHeader = styled.h4`
  font-size: 16px;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 20px;
`;
const LeftRoomDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const RoomDetailLine = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
`;

const RoomLabel = styled.span`
  font-weight: 600;
  color: #2c3e50;
  font-size: 16px;
`;

const RoomValue = styled.span`
  color: #555;
  font-size: 15px;
`;

const AmenityHeader = styled.h5`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #2c3e50;
`;

const AmenityItem = styled.li`
  font-size: 14px;
  margin-left: 20px;
  margin-bottom: 4px;
  color: #555;
`;

const RightDetailsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
`;

const ReservationButton = styled.button`
  padding: 12px 24px;
  border: 1.5px solid black;
  background: transparent;
  color: black;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    background-color: #2c6e49;
    color: white;
  }
`;

const HorizontalAmenityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const AmenityGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 200px; /* prevents text from wrapping too narrowly */
`;

const AmenityDivider = styled.div`
  width: 1px;
  background-color: #ccc;
  height: auto;
  margin: 0 10px;
`;

export {
  StRooms,
  Spacer,
  SpacerOverlay,
  SpacerContainer,
  RoomContentWrapper,
  BuildingText,
  RoomGridWrapper,
  RoomGridTile,
  RoomGridOverlay,
  RoomGridTitle,
  ArrowLine,
  Line,
  Arrow,
  RoomSelectorWrapper,
  RoomSelectorButton,
  InfoGridWrapper,
  InfoGridBlock,
  InfoTitle,
  InfoIcon,
  InfoText,
  InfoTextSmall,
  FullRoomInfoSection,
  LeftRoomDetails,
  RightAmenitySection,
  SmallHeader,
  RoomDetailLine,
  RoomLabel,
  RoomValue,
  AmenityGroup,
  AmenityHeader,
  AmenityItem,
  RightDetailsWrapper,
  ReservationButton,
  HorizontalAmenityWrapper,
  AmenityDivider
};
