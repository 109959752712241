import React, { useState } from 'react';
import {
  StRooms,
  Spacer,
  SpacerOverlay,
  SpacerContainer,
  RoomContentWrapper,
  BuildingText,
  RoomGridWrapper,
  RoomGridTile,
  RoomGridOverlay,
  RoomGridTitle,
  ArrowLine,
  Line,
  Arrow,
  RoomSelectorWrapper,
  RoomSelectorButton,
  InfoGridWrapper,
  InfoGridBlock,
  InfoTitle,
  InfoIcon,
  InfoText,
  InfoTextSmall,
  FullRoomInfoSection,
  LeftRoomDetails,
  SmallHeader,
  RoomDetailLine,
  RoomLabel,
  RoomValue,
  AmenityGroup,
  AmenityHeader,
  AmenityItem,
  RightDetailsWrapper,
  ReservationButton,
  HorizontalAmenityWrapper,
  AmenityDivider
} from './styles';
import { buildings } from '../../data/roomInfo';
import { useNavigate } from 'react-router-dom';

function Rooms() {
  const navigate = useNavigate();

  const allRooms = buildings.flatMap((building) =>
    Object.entries(building.rooms).map(([roomNum, roomData]) => ({
      buildingName: building.buildingName,
      roomNum,
      ...roomData
    }))
  );

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [roomIndex, setRoomIndex] = useState(0);
  const currentRoom = allRooms[roomIndex];

  const handleRoomArrow = (direction: 'left' | 'right') => {
    if (direction === 'left') {
      setRoomIndex((prev) => (prev === 0 ? allRooms.length - 1 : prev - 1));
    } else {
      setRoomIndex((prev) => (prev === allRooms.length - 1 ? 0 : prev + 1));
    }
  };

  return (
    <StRooms src={currentRoom.imageUrl[0]}>
      <Spacer>
        <SpacerOverlay />
        <SpacerContainer>
          <ArrowLine onClick={() => handleRoomArrow('left')}>
            <Line />
            <Arrow>{'< prev'}</Arrow>
          </ArrowLine>

          <BuildingText>{`${currentRoom.buildingName} ${currentRoom.roomNum}`}</BuildingText>

          <ArrowLine right onClick={() => handleRoomArrow('right')}>
            <Arrow>{'next >'}</Arrow>
            <Line />
          </ArrowLine>
        </SpacerContainer>
      </Spacer>

      <RoomContentWrapper>
        <RoomSelectorWrapper>
          {allRooms.map((room, i) => (
            <RoomSelectorButton key={i} isActive={i === roomIndex} onClick={() => setRoomIndex(i)}>
              {room.buildingName} {room.roomNum}
            </RoomSelectorButton>
          ))}
        </RoomSelectorWrapper>

        <InfoGridWrapper>
          <InfoGridBlock>
            <InfoTitle>room type</InfoTitle>
            <InfoIcon>🏠</InfoIcon>
            <InfoText>{currentRoom.category}</InfoText>
          </InfoGridBlock>
          <InfoGridBlock>
            <InfoTitle>personnel</InfoTitle>
            <InfoIcon>👥</InfoIcon>
            <InfoText>{currentRoom.personnel}</InfoText>
          </InfoGridBlock>
          <InfoGridBlock>
            <InfoTitle>time</InfoTitle>
            <InfoIcon>🕒</InfoIcon>
            <InfoText>15:00 / 11:00</InfoText>
            <InfoTextSmall>체크인 / 체크아웃</InfoTextSmall>
          </InfoGridBlock>
        </InfoGridWrapper>

        <FullRoomInfoSection>
          <LeftRoomDetails>
            <SmallHeader>ROOM DETAILS</SmallHeader>
            <RoomDetailLine>
              <RoomLabel>인원 기준 / 최대:</RoomLabel>
              <RoomValue>{currentRoom.personnel}</RoomValue>
            </RoomDetailLine>
            <RoomDetailLine>
              <RoomLabel>객실 크기:</RoomLabel>
              <RoomValue>정보 없음</RoomValue>
            </RoomDetailLine>
            <RoomDetailLine>
              <RoomLabel>유형:</RoomLabel>
              <RoomValue>{currentRoom.category}</RoomValue>
            </RoomDetailLine>
            <RoomDetailLine>
              <RoomLabel>특이 사항:</RoomLabel>
              <RoomValue>{currentRoom.significant?.join(', ') ?? '없음'}</RoomValue>
            </RoomDetailLine>
          </LeftRoomDetails>

          <RightDetailsWrapper>
            <ReservationButton onClick={() => navigate('/reservation')}>예약하기</ReservationButton>

            <HorizontalAmenityWrapper>
              <AmenityGroup>
                <AmenityHeader>📶 일반 편의시설</AmenityHeader>
                {currentRoom.general.map((item, idx) => (
                  <AmenityItem key={`general-${idx}`}>{item}</AmenityItem>
                ))}
              </AmenityGroup>

              <AmenityDivider />

              <AmenityGroup>
                <AmenityHeader>🍳 주방 시설</AmenityHeader>
                {currentRoom.cooking.map((item, idx) => (
                  <AmenityItem key={`kitchen-${idx}`}>{item}</AmenityItem>
                ))}
              </AmenityGroup>

              <AmenityDivider />

              <AmenityGroup>
                <AmenityHeader>🧼 위생 용품</AmenityHeader>
                {currentRoom.hygiene.map((item, idx) => (
                  <AmenityItem key={`hygiene-${idx}`}>{item}</AmenityItem>
                ))}
              </AmenityGroup>
            </HorizontalAmenityWrapper>
          </RightDetailsWrapper>
        </FullRoomInfoSection>

        {/* Room Grid Navigation Section */}
        <div style={{ width: '100%' }}>
          <RoomGridWrapper>
            {allRooms.map((room, index) => (
              <RoomGridTile
                key={`${room.buildingName}-${room.roomName}`}
                image={room.imageUrl[0]}
                onClick={() => {
                  setRoomIndex(index);
                  setCurrentImageIndex(0);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                <RoomGridOverlay>
                  <RoomGridTitle>{`${room.buildingName} ${room.roomName}`}</RoomGridTitle>
                </RoomGridOverlay>
              </RoomGridTile>
            ))}
          </RoomGridWrapper>
        </div>
      </RoomContentWrapper>
    </StRooms>
  );
}

export default Rooms;
