import {
  StHeader,
  LogoDiv,
  LogoLink,
  LogoImage,
  LogoText,
  NavBar,
  NavBarRight,
  NavBarItem,
  StyledLink,
  ReservationLink
} from './styles';

function Header() {
  return (
    <StHeader>
      <LogoDiv>
        <LogoLink to="/">
          <LogoImage src="S9Logo.jpeg" alt="S9 Logo" />
          <LogoText>S9Tails</LogoText>
        </LogoLink>
      </LogoDiv>

      <NavBar>
        <NavBarRight>
          <NavBarItem>
            <StyledLink to="/Rooms">객실</StyledLink>
          </NavBarItem>
          <NavBarItem>
            <StyledLink to="/Facilities">시설</StyledLink>
          </NavBarItem>
          <NavBarItem>
            <StyledLink to="/Travel">관광</StyledLink>
          </NavBarItem>
          <NavBarItem>
            <ReservationLink to="/Reservation">예약</ReservationLink>
          </NavBarItem>
        </NavBarRight>
      </NavBar>
    </StHeader>
  );
}

export default Header;
