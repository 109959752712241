import React from 'react';
import {
  StFooter,
  FooterContent,
  LogoImage,
  LogoText,
  InfoText,
  ReservationButton,
  Divider,
  CopyrightText
} from './styles';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  return (
    <StFooter>
      <FooterContent>
        {/* Logo Placeholder */}
        <LogoImage src="S9Logo.jpeg" alt="S9 Pension Logo" />

        {/* Title */}
        <LogoText>S9 Pension</LogoText>

        {/* Info */}
        <InfoText>경기 남양주시 수동면 축령산로 263번길34</InfoText>
        <InfoText>문의: 010-1234-5678</InfoText>
        <InfoText>대표자: 오영식</InfoText>
        <InfoText>BANK_ 에스나인펜션(본부동): 신한 110-443-915084 오영식</InfoText>
        <InfoText>스페셜하우스(연두동): 농협 352-1357-9094-13 육근창</InfoText>
        <InfoText>스마일하우스(파랑동): 우리은행 1002-258-796497 박창환</InfoText>
        <InfoText>스프렌도어하우스(노랑동): 농협 352-1761-4544-93 이옥영</InfoText>
        <InfoText>석세스하우스(황토동): 농협 546-01-007054 서경숙</InfoText>

        {/* Button */}
        <ReservationButton
          onClick={() => {
            navigate('/reservation');
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          예약하기
        </ReservationButton>

        {/* Divider & Copyright */}
        <Divider />
        <CopyrightText>@2024 S9 Pension | All Rights Reserved</CopyrightText>
      </FooterContent>
    </StFooter>
  );
}

export default Footer;
